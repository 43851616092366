/*-----------------------------------------------
|   Toast
-----------------------------------------------*/
.toast {
  background-color: $gray-200;
}
.toast-success {
  background-color: $success;
}
.toast-error {
  background-color: $danger;
}
.toast-info {
  background-color: $info;
}
.toast-warning {
  background-color: $warning;
}
