/*-----------------------------------------------
|   Select 2
-----------------------------------------------*/
.select2.select2-container{
  width: 100% !important;
  .select2-search--inline .select2-search__field{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple{
  border-color: $input-border-color;
}
.select2-container--default .select2-search--inline .select2-search__field{
  margin-top: 7px;
  font-size: map_get($font-sizes, '-1');
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li{
  line-height: 20px;
}
.selectpicker option{
  visibility: hidden;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice{
  font-size: 0.83333rem;
  border: 1px solid map_get($grays, '200');
  background-color: $light;
}

.border-0 + .select2 .select2-selection{ border: none !important; }
.select2-container--default {
  .select2-selection--single, .select2-search--dropdown .select2-search__field{ border-color: $border-color; }
}
.select2-dropdown{
  border-color: $border-color;
  z-index: 1051 !important;
}
